import React, { useState, useRef } from "react";
import { Box, Button, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Paper, Tooltip, IconButton } from "@mui/material";
import { Time } from "./../../utils/context/Timezone";
import { styled } from "@mui/system";
import { Edit as EditIcon, Search as SearchIcon, Tag } from "@mui/icons-material";
import EventLabelModal from "../../components/Modules/CatLitterBox/Events/EventLabelModal";
import axiosInstance from "../../api/axios/axiosInstance";
import { saveAs } from 'file-saver';
import { toast, ToastContainer, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { object } from "yup";

const SearchBar = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
}));

const HeaderButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    '&:hover': {
        backgroundColor: theme.palette.grey[800],
    },
    borderRadius: theme.shape.borderRadius,
}));

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey[200],
}));

const CatLink = ({ name, cat_id }) => {
    return (
        <a href={`/cats/${cat_id}`} target="_blank" rel="noopener noreferrer">
            {name}
        </a>
    );
};

const DogCollarLabelsAndClassifications = ({ eventId, newData, refetch }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [merror, setError] = React.useState();
    const [videoURL, setVideoURL] = React.useState();
    const [sensorData, setsensorData] = useState(null);
    const [isLoadingSensorCSV, setisLoadingSensorCSV] = React.useState(true);
    const [isLoadingCSV, setisLoadingCSV] = React.useState(true);
    const [csvData, setCsvData] = useState(null);
    const [eventIds, seteventIds] = useState(null);
    const fileInputRef = useRef();

    const values = [
        { title: "Pet Name", classificationKey: "pet_name" },
        { title: "Video Start Time", classificationKey: "video_start_time" },
        { title: "Video End Time", classificationKey: "video_end_time" },
        { title: "UTC", classificationKey: "startTime" },
        { title: "Binary Files", classificationKey: "cloud_movement_data_file_url" },
        { title: "Video File Name", classificationKey: "file_name" },
        { title: "Session Video", classificationKey: "cloud_video_file_url" },
        { title: "Label File", classificationKey: "label_file_name" }
    ];

    React.useEffect(() => {
        downloadVideo();
        download_movement_file();
        get_label_file();
        getVideoEventIds();
    }, []);

    const getVideoEventIds = () => {
        const petId = newData[0]["pet_id"]
        const cloudVideoUrl = newData[0]["cloud_video_file_url"]
        axiosInstance.post('/get-dog-collar-video-eventids',{
            cloud_video_url: cloudVideoUrl, pet_id: petId,
          }, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
            })
          .then((response, error) => {
            setisLoadingSensorCSV(false);
            if (error) {
              setError(error)
    
              return;
            }
            if (typeof (response.data) != 'object' && response.data.data.length <= 0) {
                seteventIds(null)
            } else {
                seteventIds(response.data.data)
            }
    
          }).catch((error) => {
            setisLoadingSensorCSV(false);
          });
    }


    const download_movement_file = () => {
        const eventID = newData[0]["id"]
        const source = newData[0]["source"]
        axiosInstance.get('/get-dog-collar-movement-file-download?event_id=' + eventID + '&source=' + source)
          .then((response, error) => {
            setisLoadingSensorCSV(false);
            if (error) {
              setError(error)
    
              return;
            }
            if (typeof (response.data) != 'string' || response.data.data == "No Data Available") {
              setsensorData("No Data Available")
            } else {
              setsensorData(response.data)
            }
    
          }).catch((error) => {
            setisLoadingSensorCSV(false);
            toast.error('[API Error]: Request Failed with Status Code 500', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          });
      }

    const downloadVideo = () => {
        const cloud_video_url = newData[0]["cloud_video_file_url"]
        const eventID = newData[0]["id"]
        if (cloud_video_url != "") {
            axiosInstance.get('/get-dog-collar-video-download?event_id=' + eventID + '&video_url=' + cloud_video_url)
                .then((response, error) => {
                    if (error) {
                        setError(error)
                        setIsLoading(false);
                        return;
                    }
                    setIsLoading(false);
                    setVideoURL(response.data.data)

                }).catch((error) => {
                    setIsLoading(false);
                    toast.error('[API Error]: Request Failed with Status Code 500', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                });
        }
    }

    const get_label_file = () => {
        const eventID = newData[0]["id"]
        const startTime = newData[0]["startTime"]
        axiosInstance.get('/get-dog-collar-label-file-download?event_id=' + eventID + '&startTime=' + startTime)
          .then((response, error) => {
            setisLoadingCSV(false);
            if (error) {
              setError(error)
    
              return;
            }
            if (response.data.data === 'Label File Not Available') {
              setCsvData(null)
            } else {
              setCsvData(response.data.data)
            }
    
          }).catch((error) => {
            setisLoadingCSV(false);
            toast.error('[API Error]: Request Failed with Status Code 500', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          });
      }

      const handleUpload = (label_file) => {
        
        const allEventIds = eventIds[0]
        const event = newData[0]

        let uploaded_label_file = label_file.target.files[0]
        let device_serial_nos = JSON.stringify(allEventIds.device_serial_nos)
        let session_ids = JSON.stringify(allEventIds.session_ids)

        const request_data = {
              cloud_video_file_url: event.cloud_video_file_url, pet_id: event.pet_id,
              device_serial_nos: device_serial_nos, file_name: event.file_name,
              session_ids: session_ids, pet_name: event.pet_name, source: event.source, startTime: event.startTime,
              video_end_time: event.video_end_time, video_start_time: event.video_start_time,
              label_file: uploaded_label_file, label_file_name: uploaded_label_file.name
            }
            
    
        axiosInstance.post('/get-dog-collar-upload-labels?pet_id=' + event.pet_id, {
            cloud_video_file_url: event.cloud_video_file_url, pet_id: event.pet_id,
            device_serial_nos: device_serial_nos, file_name: event.file_name,
            session_ids: session_ids, pet_name: event.pet_name, source: event.source, startTime: event.startTime,
            video_end_time: event.video_end_time, video_start_time: event.video_start_time,
            label_file: uploaded_label_file, label_file_name: uploaded_label_file.name
        }, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }).then((response, error) => {
          if (error) {
            setError(error)
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          if (response.data.message == "File Columns Mismatch") {
            toast.error('Request Failed, File Header Mismatch', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
          else if (response.data.message == "File upload failed") {
            toast.error('Request Failed, File upload failed', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
          else {
            toast('Label File Uploaded!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
    
    
            setisLoadingCSV(true)
            get_label_file(event)
          }
        }).catch((error) => {
            setIsLoading(false);
          toast.error('[API ERROR]: Request Failed', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        });
      };

    const handleDownload = () => {
        window.location.href = videoURL;
    };

    const handleDownloadSensorCSV = () => {
        const blob = new Blob([sensorData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = newData[0]["id"] + '.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      };
    
      const handleDownloadCSV = () => {
        window.location.href = csvData.url;
      };

    const renderFn = (key) => {
        if (key == "startTime" || key == "video_start_time" || key == "video_end_time") {
            return <Time>{newData[0][key]}</Time>;
        }
        else if (key == "cloud_movement_data_file_url") {
            if (sensorData != "No Data Available" && isLoadingSensorCSV == false) {
            return <a onClick={handleDownloadSensorCSV} download style={{ color: "#096dd9", cursor: "pointer" }}>
            Download CSV
          </a>
            }
            else{
                return <span>No data available</span>
            }
        }
        else if (key == "cloud_video_file_url") {
            if (videoURL != "" && videoURL != undefined) {

                return (
                    <a onClick={handleDownload} download style={{ color: "#096dd9", cursor: "pointer" }}>
                        Download Video
                    </a>)
            }
            else {
                return <span>No Video available for Download</span>
            }
        }
        else if (key == "label_file_name") {
            if (csvData != null && isLoadingCSV == false) {

                return (
                    <a onClick={handleDownloadCSV} download style={{ color: "#096dd9", cursor: "pointer" }}>
                        {csvData.label_file_name}
                      </a>)
            }
            else {
                return <span>No data available</span>
            }
        }
        else {
            return `${newData[0][key]}`;
        }
        
    };

    const [newEventModalIsOpen, setEventModalIsOpen] = React.useState(false);

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" fontWeight="bold">
                    Classification and Labels
                </Typography>
                
                <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={() => fileInputRef.current.click()}
                    sx={{
                        backgroundColor: "#000",
                        color: "#fff",
                        borderRadius: "50px",
                        padding: "5px 15px",
                        textTransform: "none",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "12px",
                        "&:hover": {
                            backgroundColor: "#333",
                        },
                        "& .MuiButton-startIcon": {
                            marginRight: "5px",
                        },
                    }}
                >
                    Upload Labels
                </Button>
                <input onChange={handleUpload} multiple={false} ref={fileInputRef} type='file' hidden />
            </Box>
            {/* <SearchBar>
                <SearchIcon sx={{ mr: 1 }} />
                <TextField variant="standard" placeholder="Search" fullWidth InputProps={{ disableUnderline: true }} />
            </SearchBar> */}
            <TableContainer component={Paper}>
                <Table aria-label="labels and classifications table">
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell>Title</TableHeaderCell>
                            <TableHeaderCell>Value</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.map((v, i) => (
                            <TableRow key={i}>
                                <TableCell>{v.title}</TableCell>
                                {/* <TableCell>{newData[0]["pet_name"]}</TableCell> */}
                                <TableCell>{renderFn(v.classificationKey)}</TableCell>
                                {/* <TableCell>{renderFn(labels[v.labelKey], v, "label")}</TableCell> */}
                                {/* <TableCell>{renderFn(normalisedClassification[v.normalisedClassificationKey ?? v.classificationKey], v, "normalised")}</TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {newEventModalIsOpen && (
                <EventLabelModal
                    isModalOpen={newEventModalIsOpen}
                    closeModal={() => setEventModalIsOpen(false)}
                    eventId={eventId}
                />
            )}
            <ToastContainer />
        </Box>
        
    );
};

export default DogCollarLabelsAndClassifications;
