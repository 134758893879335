import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useParams, useNavigate } from "react-router-dom";

import moment from "moment-timezone";
import { useTimezone } from "./../../../utils/context/Timezone";
import FrankenCatBowlAttributes from "./FrankenCatBowlAttributes";
import FrankenCatBowlEvents from "./FrankenCatBowlEvents";
import FrankenBowlVideoEvents from "./FrankenCatBowlVideoEvents";

const FrankenCatBowl = () => {
  const timezone = useTimezone();
  const { catId, source } = useParams();
  const navigate = useNavigate();
  const [newData, setNewData] = useState(null);
  const [reloading, setReloading] = useState(0);
  const [isLoading, setisLoading] = React.useState(true);
  useEffect(() => {
    setisLoading(false);
    
  }, []);

  const machineSerialNumber = newData?.data.sn;
  const machineName = newData?.data.name;
  const isFrozen = newData?.data.isFrozen;
  const frozenAt = newData?.data.mostRecentUploadAt;

  const formatDateBySelectedTimezone = (date) =>
    moment(date + "Z")
      .tz(timezone)
      .format("YYYY/MM/DD - HH:mm:ss");

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
        <ArrowBackIcon sx={{ marginRight: 1 }} />
        <Typography
          component={Link}
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          sx={{
            color: "black",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          Go Back
        </Typography>
      </Box>


      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Attributes
          </Typography>
          <FrankenCatBowlAttributes setReloading={setReloading} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Events By Video
          </Typography>
          <FrankenBowlVideoEvents />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Events
          </Typography>
          <FrankenCatBowlEvents />
        </Grid>

        

      </Grid>
    </Box>
  );
};

export default FrankenCatBowl;
