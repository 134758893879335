import React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import axiosInstance from "./../../../api/axios/axiosInstance";
import Page from "./../../../utils/Page";
import DogCollarEventKeyInfo from "./DogCollarEventKeyInfo";
import DogCollarEventLoadCellChart from "../../../utils/eventUtills/DogCollarEventLoadCellChart";
import DogCollarNodeLabelsAndClassifications from "../../../utils/eventUtills/DogCollarNodeLabelsAndClassifications";
import { toast, ToastContainer, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export const DogCollarEventView = ({ eventId }) => {
  const { dogId, source } = useParams()
  const [newData, setNewData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);


  React.useEffect(() => {
    dataLoad();
  }, [eventId]);

  const dataLoad = async() => {
    try {
      const response = await axiosInstance.post(
        "/get-dog-collar-pets-events?pet_id=" + dogId,
        {
          count: 30, pet_id: dogId, page: 1, snSuffixSessionID: eventId, source: source
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setNewData(response.data.data);
      
    } catch (error) {
      console.error(error);
      toast.error('[API Error]: Request Failed with Status Code 500', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const url = source === "dcf1" ? 'get-dog-frankencollar-loadcelldata' : 'get-dog-collar-loadcelldata';


  return (
    <Page
      title="Event"
      breadcrumbs={[
        {
          title: "Household",
          link: `/households/${newData?.household_id}`,
        },
        {
          title: "Monitor",
          link: `/monitors/${newData?.machine_id}`,
        },
        { title: eventId },
      ]}
    >
      {!isLoading ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DogCollarEventKeyInfo eventId={eventId} serialNumber={newData?.machine_id} source={source} dogId={dogId}/>
          </Grid>
          <Grid item xs={12}>
            <DogCollarEventLoadCellChart
              eventId={eventId}
              sessionStartTime={newData?.session_start_date}
              apiUrl={url}
              source={source}
            />
          </Grid>
          
          <Grid item xs={12}>
            <DogCollarNodeLabelsAndClassifications newData={newData} refetch={dataLoad} />
          </Grid>
          
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Page>
  );
};

export const DogCollarEventSingleView = () => {
  const { eventId } = useParams();
  return <DogCollarEventView eventId={eventId} />;
};

const DogCollarEventSingleViewEB = () => (
    <DogCollarEventSingleView />
);

export default DogCollarEventSingleViewEB;
