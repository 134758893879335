import React, { useState } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  IconButton
} from "@mui/material";
import { Search as SearchIcon, FilterList as FilterListIcon } from "@mui/icons-material";
import { styled } from "@mui/system";
import { Time } from "../context/Timezone";

const useTableStyles = styled((theme) => ({
  noData: {
    opacity: 0.4,
    fontStyle: "italic",
  },
  ul: {
    margin: 0,
    paddingLeft: "1rem",
  },
  header: {
    fontWeight: "bold",
    backgroundColor: theme.palette.grey[200],
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: '0 10px',
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    color: theme.palette.common.white,
    flex: 1,
  },
  filterIcon: {
    marginLeft: theme.spacing(2),
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
}));



const CatFeederRawClassifications = ({ rawClassifications }) => {
  const styles = useTableStyles();

  const columns = [
    { title: "Model Name", dataIndex: "modelName" },
    { title: "Model Version", dataIndex: "modelVersion" },
    { title: "Type", dataIndex: "types"},
    { title: "Value", dataIndex: "value"},
    { title: "Received At", dataIndex: "receivedAt", render: (text) => <Time>{text}</Time> },
  ];

  return (
    <Box data-testid={"eventView.rawClassificationsTable"} sx={{ padding: 2 }}>
      <Box className={styles.titleRow}>
        <Typography variant="h6" fontWeight="bold">
          Raw Classifications
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        
        <Box sx={{ flexGrow: 1 }} />
        <IconButton className={styles.filterIcon}>
          <FilterListIcon />
        </IconButton>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="raw classifications table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.title} className={styles.header}>
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rawClassifications.length ? (
              rawClassifications.map((record, index) => (
                <TableRow key={`${record.modelName}-${record.modelVersion}-${record.type}-${index}`}>
                  {columns.map((column) => (
                    <TableCell key={column.dataIndex}>
                      {column.render ? column.render(record[column.dataIndex], record) : record[column.dataIndex]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <Typography className={styles.noData}>No Data</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
    </Box>
  );
};

export default CatFeederRawClassifications;
