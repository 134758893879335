import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Collapse,
  IconButton,
  Button,
} from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useParams } from "react-router-dom";
import axiosInstance from "./../../../../api/axios/axiosInstance"; // Adjust the import path
import moment from "moment-timezone";
import { Edit as EditIcon } from "@mui/icons-material";
import EventLabelModal from "../Events/EventLabelModal";
import PersonIcon from "@mui/icons-material/Person";
import { useMassFormatter, useMassUnits } from "../../../../utils/context/mass";
import { DateRangePicker } from "rsuite";
import Pagination from "../../../../utils/Pagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useTableStyles = styled((theme) => ({
  noData: {
    opacity: 0.4,
    fontStyle: "italic",
  },
  ul: {
    margin: 0,
    paddingLeft: "1rem",
  },
  header: {
    fontWeight: "bold",
    backgroundColor: theme.palette.grey[200],
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: "0 10px",
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    color: theme.palette.common.white,
    flex: 1,
  },
  filterIcon: {
    marginLeft: "auto",
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
}));

const EventRow = ({ event, refreshEventList }) => {
  const [expanded, setExpanded] = useState(false);
  const [newEventModalIsOpen, setEventModalIsOpen] = React.useState(false);
  const { massFormatter } = useMassFormatter();
  const massUnits = useMassUnits();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const format = `${dateFormat} ${timeFormat}`;

  const classifications = {
    ...event.classifications,
    startTime: event.startTime,
  };

  // TOTO: Remove Z addition after explicitly UTC times are provided on the API
  const date = moment(event.startTime + "Z").format(format);

  const hasEntryTime = objectHasPropertyOfType(event, "entry", "string");
  const displayEntryTime = hasEntryTime
    ? moment(event.entry + "Z").format(format)
    : "";
  const hasExitTime = objectHasPropertyOfType(event, "exit", "string");
  const displayExitTime = hasExitTime
    ? moment(event.exit + "Z").format(format)
    : "";

  const hasEliminationStart = objectHasPropertyOfType(
    event.classifications,
    "eliminationStart",
    "string"
  );
  const displayEliminationStart = hasEliminationStart
    ? moment(event.classification.eliminationStart + "Z").format(format)
    : "";

  const hasEliminationEnd = objectHasPropertyOfType(
    event,
    "eliminationEnd",
    "string"
  );
  const displayEliminationEnd = hasEliminationEnd
    ? moment(event.classification.eliminationEnd + "Z").format(format)
    : "";
  const hasMass =
    event.classifications.weight !== null &&
    typeof event.classifications.weight === "string";

  const hasLabels =
    event.labels?.cat?.cat_id !== null ||
    event.labels?.eliminationStartEnd !== null ||
    event.labels?.falseTrigger !== null ||
    event.labels?.isCat !== null ||
    event.labels?.observedEliminationEnd !== null ||
    event.labels?.observedEntry !== null ||
    event.labels?.observedExit !== null ||
    event.labels?.observedEliminationStart !== null ||
    event.labels?.tags !== null;
  return (
    <>
      <TableRow>
        <TableCell  style={{ cursor: "default" }} onClick={() => handleExpandClick()}>
          {hasLabels && (
            <PersonIcon
              style={{
                backgroundColor: "#ED1C25",
                transform: "scale(1.5)",
              }}
            />
          )}
        </TableCell>
        <TableCell style={{ cursor: "default" }} onClick={() => handleExpandClick()}>{date}</TableCell>
        <TableCell style={{ cursor: "default" }} onClick={() => handleExpandClick()}>{displayEntryTime}</TableCell>
        <TableCell style={{ cursor: "default" }} onClick={() => handleExpandClick()}>
          {displayEliminationStart + " - " + displayEliminationEnd}
        </TableCell>
        <TableCell style={{ cursor: "default" }} onClick={() => handleExpandClick()}>{displayExitTime}</TableCell>
        <TableCell style={{ cursor: "default" }} onClick={() => handleExpandClick()}>
          {" "}
          {event.isCat === null || !event.isCat
            ? ""
            : event.cat && event.cat.name}
        </TableCell>
        <TableCell style={{ cursor: "default" }} onClick={() => handleExpandClick()}>
          {hasMass
            ? `${massFormatter(event.classifications.weight)}${massUnits}`
            : null}
        </TableCell>
        <TableCell>
          <EditIcon
            onClick={() => setEventModalIsOpen(true)}
            style={{ color: "#1976d2", cursor: "pointer" }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleExpandClick()}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow style={{backgroundColor:"#6c757d1a"}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box padding={3}>
              <br></br>
              <Typography variant="body2">
                <b>Monitor SN:</b> {event.serial_no}
              </Typography>
              <Typography variant="body2">
                <b>Event ID:</b> {event.id}
              </Typography>
              <Typography variant="body2">
                <b>UTC:</b> {event.startTime}
              </Typography>
              <br></br>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {newEventModalIsOpen && (
        <EventLabelModal
          isModalOpen={newEventModalIsOpen}
          closeModal={() => {
            setEventModalIsOpen(false);
            refreshEventList(1);
          }}
          eventId={event.id}
        />
      )}
    </>
  );
};

const EventTable = ({
  events,
  isLoading,
  refreshEventList,
  setPage,
  total,
}) => {
  const styles = useTableStyles();

  return (
    <Box sx={{ padding: 2 }}>
      <Pagination
        total={total}
        disabled={isLoading}
        setPage={setPage}
        pageName="eventsPage"
        smallMarginBottom
      />

      <TableContainer component={Paper}>
        <Table aria-label="event table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.header}></TableCell>
              <TableCell className={styles.header}>Date</TableCell>
              <TableCell className={styles.header}>Entry</TableCell>
              <TableCell className={styles.header}>
                Elimination Start - End
              </TableCell>
              <TableCell className={styles.header}>Exit</TableCell>
              <TableCell className={styles.header}>Cat</TableCell>
              <TableCell className={styles.header}>Weight</TableCell>
              <TableCell className={styles.header}>#</TableCell>
              <TableCell className={styles.header}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              events.map((event) => (
                <EventRow
                  key={event.id}
                  event={event}
                  refreshEventList={refreshEventList}
                />
              ))
            )}
          </TableBody>
        </Table>
        {events?.length == 0 &&
                    <Typography variant="h6" style={{ marginLeft: "640px", width: "50%", padding: "10px" }}> No Data Found </Typography>
                }
      </TableContainer>
      <Pagination
        total={total}
        disabled={isLoading}
        setPage={setPage}
        pageName="eventsPage"
        smallMarginBottom
      />
    </Box>
  );
};

const objectHasPropertyOfType = (object, property, type) => {
  return (
    Object.prototype.hasOwnProperty.call(object, property) &&
    typeof object[property] === type
  );
};

const MachineEvents = () => {
  const { machineId } = useParams();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [value, setValue] = React.useState(new Date());

  const handleStartDateChange = (date) => {
    if (date != null && date.length > 0) {
      const start = moment(date[0]).format("YYYY-MM-DD");
      setStartDate(start);
      const end = moment(date[1]).format("YYYY-MM-DD");
      setEndDate(end);
      setValue(date);
    } else {
      setValue(new Date());
      setStartDate(null);
      setEndDate(null);
    }
  };

  const fetchData = async (page, startDate, endDate) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        "/machine-events",
        {
          count: 30,
          machine_id: machineId,
          page: page,
          from_date: startDate,
          to_date: endDate,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setEvents(response.data.data);
      setTotal(response.data.pageInfo.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, startDate, endDate);
  }, [machineId, setEvents, page]);

  const refreshEventList = (page = 1) => {
    setPage(page);
    setIsLoading(true);
    fetchData(page, startDate, endDate);
  };

  return (
    <Box>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          marginTop: "10px",
          paddingRight: "148px",
        }}
      >
        <DateRangePicker value={value} onChange={handleStartDateChange} />
        <Button
          style={{ marginBottom: "14px" }}
          key="filter"
          onClick={() => refreshEventList(page)}
        >
          Filter
        </Button>
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={() => refreshEventList(page)}
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            borderRadius: "50px",
            padding: "5px 15px",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            marginBottom: "20px",
            "&:hover": {
              backgroundColor: "#333",
            },
            "& .MuiButton-startIcon": {
              marginRight: "10px",
            },
          }}
        >
          Reload
        </Button>
      </div>
      <EventTable
        events={events}
        isLoading={isLoading}
        refreshEventList={refreshEventList}
        page={page}
        setPage={setPage}
        total={total}
      />
    </Box>
  );
};

export default MachineEvents;
