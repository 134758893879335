import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Grid,
  Button,
  Checkbox,
  Tooltip,
  IconButton,
  Collapse,
} from "@mui/material";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../api/axios/axiosInstance";
import moment from "moment-timezone";
import LoadingSpinner from "../../LoadingSpinner/component";
import { useSearchParams } from "../../../utils/useSearchParams";
import SessionIDSearch from "../../../utils/SessionIDSearch";
import { blue } from "@mui/material/colors";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { saveAs } from "file-saver";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CatFeederEventLabelModal from "./CatFeederEventLabelModal";
import PersonIcon from "@mui/icons-material/Person";
import { Edit as EditIcon } from "@mui/icons-material";
// import { Timezone } from "../../../../utils/context/Timezone";
import { useTimezone } from "../../../utils/context/Timezone";
import Pagination from "../../../utils/Pagination";
import { CopyToClipboard } from "react-copy-to-clipboard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ImageViewer from 'react-simple-image-viewer';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const useTableStyles = styled((theme) => ({
  noData: {
    opacity: 0.4,
    fontStyle: "italic",
  },
  ul: {
    margin: 0,
    paddingLeft: "1rem",
  },
  header: {
    fontWeight: "bold",
    backgroundColor: theme.palette.grey[200],
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: "0 10px",
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    color: theme.palette.common.white,
    flex: 1,
  },
  filterIcon: {
    marginLeft: "auto",
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
}));

const CustomPagination = styled(TablePagination)(({ theme }) => ({
  "& .MuiTablePagination-toolbar": {
    justifyContent: "space-between",
  },
  "& .MuiTablePagination-actions": {
    marginLeft: theme.spacing(2),
  },
}));

const EventRow = ({ event, refreshEventList, page }) => {
  const [expanded, setExpanded] = useState(false);
  const [newEventModalIsOpen, setEventModalIsOpen] = React.useState(false);
  const { machineId } = useParams();
  const [open, setOpen] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setOpen(!open);
    if (expanded === false) {
      video_download(event);
      download_movement_file(event);
      get_label_file(event);
    }
  };
  const timezone = useTimezone();
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const format = `${dateFormat} ${timeFormat}`;
  const [videoURL, setVideoURL] = React.useState();
  const [imageURL, setImageURL] = React.useState([]);
  const [error, setError] = React.useState();
  const [sensorData, setsensorData] = useState(null);
  const [isLoading, setisLoading] = React.useState(true);
  const [isLoadingSensorCSV, setisLoadingSensorCSV] = React.useState(true);
  const [csvData, setCsvData] = useState(null);
  const [isLoadingCSV, setisLoadingCSV] = React.useState(true);
  const fileInputRef = useRef();
  const hasLabels = event.hasLabels;

  // TOTO: Remove Z addition after explicitly UTC times are provided on the API

  const download_movement_file = (event) => {
    axiosInstance
      .get(
        "/get-cat-feeder-movement-file-download?event_id=" +
        event.id +
        "&source=" +
        event.source
      )
      .then((response, error) => {
        setisLoadingSensorCSV(false);
        if (error) {
          setError(error);

          return;
        }
        if (typeof response.data != "string") {
          setsensorData(null);
        } else {
          setsensorData(response.data);
        }
      })
      .catch((error) => {
        setisLoadingSensorCSV(false);
      });
  };

  const get_label_file = (event) => {
    axiosInstance
      .get(
        "/get-cat-feeder-label-file-download?session_id=" +
        event.id +
        "&startTime=" +
        event.startTime
      )
      .then((response, error) => {
        setisLoadingCSV(false);
        if (error) {
          setError(error);

          return;
        }
        if (response.data.data === "Label File Not Available") {
          setCsvData(null);
        } else {
          setCsvData(response.data.data);
        }
      })
      .catch((error) => {
        setisLoadingCSV(false);
      });
  };

  const video_download = (event) => {
    axiosInstance
      .get(
        "/get-cat-feeder-video-download?event_id=" +
        event.id +
        "&video_url=" +
        event.cloud_video_file_url
      )
      .then((response, error) => {
        if (error) {
          setError(error);
          setisLoading(false);
          return;
        }
        setisLoading(false);
        setVideoURL(response.data.video_url);
        setImageURL(response.data.image_urls)
      })
      .catch((error) => {
        setisLoading(false);
      });
  };

  const handleUpload = (label_file) => {
    let uploaded_label_file = label_file.target.files[0];

    axiosInstance
      .post(
        "/get-cat-feeder-upload-labels?session_id=" + event.id,
        {
          cloud_video_file_url: event.cloud_video_file_url,
          pet_id: machineId,
          device_serial_nos: event.device_serial_nos,
          session_ids: event.id,
          pet_name: event.pet_name,
          source: event.source,
          startTime: event.startTime,
          label_file: uploaded_label_file,
          label_file_name: uploaded_label_file.name,
          video_start_time: event.video_start_time,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response, error) => {
        if (error) {
          setError(error);
          setisLoading(false);
          return;
        }
        setisLoading(false);
        if (response.data.message == "File Columns Mismatch") {
          toast.error("Request Failed, File Header Mismatch", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          toast("Label File Uploaded Successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          setisLoadingCSV(true);
          get_label_file(event);
        }
      })
      .catch((error) => {
        setisLoading(false);
        toast.error("[API ERROR]: Request Failed", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const CopyToClipboardButton = ({ textToCopy, testId }) => (
    <CopyToClipboard text={textToCopy}>
      <Tooltip title="Copy to clipboard">
        <IconButton data-testid={testId}>
          <FileCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );

  const [checked, setChecked] = useState(false);


  const handleChange = (e) => {
    // setChecked(true)
    setChecked(e.target.checked);
    let selectedEvents = localStorage.getItem("selectedEvents");
    let selectedVideos = localStorage.getItem("selectedVideos");
    if (e.target.checked == true) {
      const value = e.target.value;
      if (
        selectedEvents != " " &&
        selectedEvents != null &&
        selectedVideos != " " &&
        selectedVideos != null
      ) {
        selectedEvents = selectedEvents + "," + value.split("+")[0];
        selectedVideos = selectedVideos + "," + value.split("+")[1];
        localStorage.setItem("selectedEvents", selectedEvents);
        localStorage.setItem("selectedVideos", selectedVideos);
      } else {
        selectedEvents = value.split("+")[0];
        selectedVideos = value.split("+")[1];
        localStorage.setItem("selectedEvents", selectedEvents);
        localStorage.setItem("selectedVideos", selectedVideos);
      }
    } else {
      const value = e.target.value;
      selectedEvents = value.split("+")[0];
      selectedVideos = value.split("+")[1];
      selectedEvents = selectedEvents.replace(value, " ");
      selectedVideos = selectedVideos.replace(value, " ");
      localStorage.setItem("selectedEvents", selectedEvents);
      localStorage.setItem("selectedVideos", selectedVideos);
    }
  };

  const date = moment(event.startTime + "Z").format(format);
  const catName = event?.pet_name ?? "";

  const hasEntryTime = objectHasPropertyOfType(event, "entry", "string");
  const displayEntryTime = hasEntryTime
    ? moment(event.entry + "Z").format(format)
    : "";
  const hasExitTime = objectHasPropertyOfType(event, "exit", "string");
  const displayExitTime = hasExitTime
    ? moment(event.exit + "Z").format(format)
    : "";

  const hasEliminationStart = objectHasPropertyOfType(
    event,
    "video_start_time",
    "string"
  );
  const displayEliminationStart = hasEliminationStart
    ? moment(event.video_start_time + "Z")
      .tz(timezone)
      .format(format)
    : "";

  const hasEliminationEnd = objectHasPropertyOfType(
    event,
    "video_end_time",
    "string"
  );

  const displayEliminationEnd = hasEliminationEnd
    ? moment(event.video_end_time + "Z")
      .tz(timezone)
      .format(format)
    : "";

  const handleDownloadCSV = () => {
    window.location.href = csvData;
  };

  const handleDownload = () => {
    window.location.href = videoURL;
  };

  const handleDownloadSensorCSV = () => {
    const blob = new Blob([sensorData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = event.id + ".csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const openImage = () => {
    {
      imageURL.map((src, index) => (
        openImageViewer(index)
      ))
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Checkbox
            checked={checked}
            onChange={(e) => [handleChange(e)]}
            value={event.id + "+" + event.cloud_video_file_url}
          ></Checkbox>
        </TableCell>
        <TableCell style={{ cursor: "default" }} onClick={() => handleExpandClick()}>
          {hasLabels ? (
            <PersonIcon
              style={{ backgroundColor: "#ED1C25", transform: "scale(1.5)" }}
            />
          ) : (
            <span></span>
          )}
        </TableCell>
        <TableCell style={{ cursor: "default" }} onClick={() => handleExpandClick()}>{date}</TableCell>
        <TableCell style={{ cursor: "default" }} onClick={() => handleExpandClick()}>
          {displayEliminationStart + " - " + displayEliminationEnd}
        </TableCell>
        <TableCell style={{ cursor: "default" }} onClick={() => handleExpandClick()}> {catName} </TableCell>
        <TableCell style={{ cursor: "default" }} onClick={() => handleExpandClick()}>{event.source}</TableCell>
        <TableCell style={{ cursor: "default" }} onClick={() => handleExpandClick()}>{event.firmware_version}</TableCell>
        <TableCell>
          <EditIcon
            onClick={() => setEventModalIsOpen(true)}
            loadcell
            eventloadcell
            style={{
              color: "#1976d2",
              cursor: "pointer",
              marginLeft: "25px",
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleExpandClick()}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "#6c757d1a" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box padding={3}>
              <Typography variant="body2">
                <b>Device ID:</b> {event.device_serial_nos}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body2" copyable>
                  <b>Event ID:</b> {event.id}
                </Typography>
                <CopyToClipboardButton
                  textToCopy={event.id}
                  testId={"tids.eventView.copyEventIdToClipboard"}
                />
              </Typography>
              <Typography variant="body2">
                <b>UTC:</b> {date}
              </Typography>
              <Typography variant="body2">
                <b>Binary Files:</b>
                {isLoadingSensorCSV ? (
                  <LoadingSpinner />
                ) : sensorData ? (
                  <a
                    onClick={handleDownloadSensorCSV}
                    download
                    style={{ color: blue, cursor: "pointer" }}
                  >
                    Download CSV
                  </a>
                ) : (
                  <span>No data available</span>
                )}
              </Typography>
              <Typography variant="body2">
                <b>Label File:</b>{" "}
                {isLoadingCSV ? (
                  <LoadingSpinner />
                ) : csvData ? (
                  <a
                    onClick={handleDownloadCSV}
                    download
                    style={{ color: "#096dd9", cursor: "pointer" }}
                  >
                    Download CSV
                  </a>
                ) : (
                  <span>No data available</span>
                )}
              </Typography>
              {videoURL ? (
                <Typography variant="body2">
                  <b>Video File Name:</b> {event.file_name}
                </Typography>
              ) : (
                <span>No Video available</span>
              )}
              <Typography variant="body2">
                <b>Session Video:</b>
                {videoURL ? (
                  <a
                    onClick={handleDownload}
                    download
                    style={{ color: "#096dd9", cursor: "pointer" }}
                  >
                    Download Video
                  </a>
                ) : (
                  <span>No Video available for Download</span>
                )}
              </Typography>
              <Typography variant="body2">

                <b>YOLO Modal Images:</b>
                {imageURL.length > 0 ? (
                  <a onClick={openImage} style={{ color: "#096dd9", cursor: "pointer" }}>Click Here to View Images</a>
                ) : (
                  <span>No Images available</span>
                )}
                {isViewerOpen && (
                  <ImageViewer
                    src={imageURL}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                  />
                )}

              </Typography>
              <Typography variant="body2">
                <b>Upload:</b>
                <FileUploadIcon onClick={() => fileInputRef.current.click()} variant="contained" />

                <input
                  onChange={handleUpload}
                  multiple={false}
                  ref={fileInputRef}
                  type="file"
                  hidden
                />
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {newEventModalIsOpen && (
        <CatFeederEventLabelModal
          isModalOpen={newEventModalIsOpen}
          closeModal={() => setEventModalIsOpen(false)}
          eventId={event.id}
          startTime={event.startTime}
          videoStartTime={event.video_start_time}
          machineId={machineId}
          submitCallback={(status) => refreshEventList(page, status)}
        />
      )}
    </>
  );
};

const EventTable = ({
  events,
  isLoading,
  refreshEventList,
  page,
  setPage,
  total,
}) => {
  return (
    <Box sx={{ padding: 2, overflowY: "auto" }}>
      <Pagination
        total={total}
        disabled={isLoading}
        setPage={setPage}
        pageName="eventsPage"
        smallMarginBottom
      />

      <TableContainer component={Paper}>
        <Table aria-label="event table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell>Date</TableCell>
              <TableCell>Video Start - End</TableCell>
              <TableCell>Cat</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Firmware Version</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              events.map((event) => (
                <EventRow
                  key={event.id}
                  event={event}
                  refreshEventList={refreshEventList}
                  page={page}
                />
              ))
            )}
          </TableBody>
        </Table>
        {events?.length == 0 && (
          <Typography
            variant="h6"
            style={{ marginLeft: "640px", width: "50%", padding: "10px" }}
          >
            {" "}
            No Data Found{" "}
          </Typography>
        )}
      </TableContainer>
      <Pagination
        total={total}
        disabled={isLoading}
        setPage={setPage}
        pageName="eventsPage"
        smallMarginBottom
      />
    </Box>
  );
};

const objectHasPropertyOfType = (object, property, type) => {
  return (
    Object.prototype.hasOwnProperty.call(object, property) &&
    typeof object[property] === type
  );
};

const CatFeederMachineEvents = () => {
  const timezone = useTimezone();
  const { machineId } = useParams();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [eventtimezone, seteventTimezone] = useState(timezone);

  const [total, setTotal] = useState(0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = React.useState();

  const [searchParams, setSearchParams] = useSearchParams({
    snSuffixSessionID: "",
  });

  const handleSearch = (snSuffixSessionID) => {
    let newSearchParams = {
      ...Object.fromEntries(searchParams),
      page: page,
      snSuffixSessionID: snSuffixSessionID.trim(),
    };
    if (snSuffixSessionID === "") {
      delete newSearchParams["snSuffixSessionID"];
    }
    setIsLoading(true);
    setSearchParams(newSearchParams);
  };
  let snSuffixSessionID = searchParams.get("snSuffixSessionID");

  useEffect(() => {
    fetchData(page, machineId, startDate, endDate, snSuffixSessionID);
    localStorage.removeItem("selectedEvents")
    localStorage.removeItem("selectedVideos")
  }, [machineId, page, searchParams]);

  const fetchData = async (
    page,
    machineId,
    startDate,
    endDate,
    snSuffixSessionID
  ) => {
    try {
      setIsLoading(true);
      localStorage.removeItem("selectedEvents")
      localStorage.removeItem("selectedVideos")
      const response = await axiosInstance.post(
        "/get-cat-feeder-events?eventsPage="+page,
        {
          count: 30,
          snSuffix: machineId,
          page: page,
          snSuffixSessionID: snSuffixSessionID,
          from_date: startDate,
          to_date: endDate,
          timezone: timezone,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setEvents(response.data.data);
      // setTotal(response.data.pageInfo.totalPages * 30); // assuming 30 items per page
      setTotal(response.data.pageInfo.totalPages);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadVideo = () => {
    let selectedEvents = localStorage.getItem("selectedEvents");
    selectedEvents = selectedEvents.split(",");
    selectedEvents = selectedEvents.filter(
      (value) => Object.keys(value).length !== 0
    );
    setTimeout(() => {
      const data = {
        events: selectedEvents,
        device_id: machineId,
      };
      axiosInstance
        .post("/get-bulk-cat-feeder-videos", JSON.parse(JSON.stringify(data)), {
          responseType: "blob",
        })
        .then((response, error) => {
          if (error) {
            setError(error);
            setIsLoading(false);
            return;
          }
          // setisLoading(false);
          try {
            const blob = new Blob([response.data], { type: "application/zip" });
            saveAs(blob, machineId + ".zip");
          } catch (e) {
            console.log(e);
            toast.error("[API Error]: Request Failed with Status Code 500", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }

          // setNewData(response.data)
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }, 100);
  };

  const downloadCSV = () => {
    let selectedEvents = localStorage.getItem("selectedEvents");
    let data_events = [];
    selectedEvents = selectedEvents.split(",");
    selectedEvents = selectedEvents.filter(
      (value) => Object.keys(value).length !== 0
    );
    selectedEvents.map((event) => {
      data_events.push({
        event_id: event,
        source: "NestleCommercialPetCollar",
      });
    });
    setTimeout(() => {
      const data = {
        events: data_events,
      };
      axiosInstance
        .post(
          "/get-dog-collar-sensor-data-bulkdownload",
          JSON.parse(JSON.stringify(data))
        )
        .then((response, error) => {
          if (error) {
            setError(error);
            setIsLoading(false);
            return;
          }
          try {
            const blob = new Blob([response.data], { type: "application/ccv" });
            saveAs(blob, "dog_collar_events.csv");
          } catch (e) {
            console.log(e);
            toast.error("[API Error]: Request Failed with Status Code 500", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }

          setIsLoading(false);
          // setNewData(response.data)
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }, 100);
  };

  const refreshEventList = (page = 1, status=true) => {
    setPage(page);
    fetchData(page, machineId, startDate, endDate, snSuffixSessionID);
    setIsLoading(status);
  };

  const [value, setValue] = React.useState(new Date());

  const handleStartDateChange = (date) => {
    if (date != null && date.length > 0) {
      const start = moment(date[0]).tz(eventtimezone).format("YYYY-MM-DD");
      setStartDate(start);
      const end = moment(date[1]).tz(eventtimezone).format("YYYY-MM-DD");
      setEndDate(end);
      setValue(date);
    } else {
      setValue(new Date());
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <Box>
      <Grid item xs>
        <SessionIDSearch
          onSearch={handleSearch}
          defaultValue={searchParams.get("snSuffixSessionID") ?? ""}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: -35,
          }}
        >
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
          <Button key="downloadVideo" onClick={() => refreshEventList(page)}>
            Reload
          </Button>
          <DateRangePicker value={value} onChange={handleStartDateChange} format="yyyy-MM-dd"/>
          <Button key="filter" onClick={() => refreshEventList(page)}>
            Filter
          </Button>
          <Button
            key="BulkdownloadVideo"
            onClick={() => downloadVideo(page)}
          // icon={<DownloadOutlined />}
          >
            Download Video
          </Button>
          <Button
            key="BuldownloadCSV"
            onClick={() => downloadCSV(page)}
          // icon={<DownloadOutlined />}
          >
            Download CSV
          </Button>

          {/* </LocalizationProvider> */}
        </div>
      </Grid>
      <EventTable
        events={events}
        isLoading={isLoading}
        refreshEventList={refreshEventList}
        page={page}
        setPage={setPage}
        total={total}
      />
      <ToastContainer />
    </Box>
  );
};

export default CatFeederMachineEvents;
