import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { CircularProgress, Checkbox, FormControlLabel, Typography, Box, useTheme } from "@mui/material";
import {
    Brush,
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import axiosInstance from "./../../api/axios/axiosInstance";
import { useTimezone } from "./../../utils/context/Timezone";

const defaultTareValues = [0, 0, 0, 0];

const createLoadCellData = (
    data,
    startTime,
    sampleRate,
    timezone,
    tare = defaultTareValues,
) => {
    return data.map((datum, i) => {
        const time = moment(datum.measure_date + "Z").tz(timezone)
        const timestamp = time.format("HH:mm:ss")
        const d0 = datum.measure_value
        const d1 = datum[1] - tare[1];
        const d2 = datum[2] - tare[2];
        const d3 = datum[3] - tare[3];
        return {
            time: time.valueOf(),
            a: d0,
            b: d1,
            c: d2,
            d: d3,
            total: d0 + d1 + d2 + d3,
            timestamp,
        };
    });
};

const CatFeederEventLoadCellChart = ({ eventId, startTime }) => {
    const theme = useTheme();
    const timezone = useTimezone();
    const [applyTareWeight, setApplyTareWeight] = useState(true);
    const [newData, setNewData] = useState(null);
    const [loadCellData, setLoadCellData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [loadCellStatus, setLoadCellStatus] = useState("");

    const sDate = startTime ? moment(startTime).format("YYYY-MM-DD HH:mm:ss") : null;
    const url = `/get-cat-feeder-loadcelldata?page=1&event_id=${eventId}`;

    useEffect(() => {
        axiosInstance.get(url)
            .then((response) => {
                setNewData(response.data.data);
                setLoadCellData(response.data.data.loadCellData);
                setLoadCellStatus(response.data.data.data_status)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, [eventId]);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (loadCellStatus === "Sensor Data not Available") {
        return (
            <Typography variant="body2" color="textSecondary" style={{ textAlign: "center", verticalAlign: "middle" }}>
                No Load Cell Data available.
            </Typography>
        );
    }

    return (
        <Box sx={{ height: 400 }}>
            
            <ResponsiveContainer>
                <LineChart
                    margin={{ left: 10, right: 10 }}
                    data={createLoadCellData(
                        loadCellData.data,
                        loadCellData.startTime,
                        loadCellData.sampleRate,
                        timezone,
                        applyTareWeight && loadCellData.tare
                            ? loadCellData.tare
                            : defaultTareValues,
                    )}
                >
                    <XAxis dataKey="timestamp" />
                    <YAxis yAxisId="left">
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: "middle" }}
                        >
                            Individual
                        </Label>
                    </YAxis>
                    <YAxis dataKey="total" yAxisId="right" orientation="right">
                        <Label
                            angle={270}
                            position="right"
                            style={{ textAnchor: "middle" }}
                        >
                            Total
                        </Label>
                    </YAxis>
                    <CartesianGrid stroke={theme.palette.divider} />
                    <Tooltip />
                    <Brush />
                    <Line
                        type="monotone"
                        key="a"
                        dataKey="a"
                        stroke={theme.palette.primary.main}
                        strokeWidth={1}
                        yAxisId="left"
                        dot={false}
                    />
                    
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default CatFeederEventLoadCellChart;
