import React, { useCallback } from "react";
import { Box, CircularProgress, Button, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Typography, Paper } from "@mui/material";

import { styled } from "@mui/system";
import { Edit as EditIcon } from "@mui/icons-material";
import CatFeederEventLabelModal from "../../components/Modules/CatFeederSingleView/CatFeederEventLabelModal";
import axiosInstance from "../../api/axios/axiosInstance";
import ImageViewer from 'react-simple-image-viewer';

const SearchBar = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
}));

const HeaderButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    '&:hover': {
        backgroundColor: theme.palette.grey[800],
    },
    borderRadius: theme.shape.borderRadius,
}));

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey[200],
}));

const CatLink = ({ name, cat_id }) => {
    return (
        <a href={`/cats/${cat_id}`} target="_blank" rel="noopener noreferrer">
            {name}
        </a>
    );
};

const CatFeederLabelsAndClassifications = ({ eventId, startTime, cloud_video_file_url, firmware_version }) => {
    const [labelData, setlabelData] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [videoURL, setVideoURL] = React.useState();
    const [imageURL, setImageURL] = React.useState([]);

    const [currentImage, setCurrentImage] = React.useState(0);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);

    const values = [
        { title: "Pet Name", classificationKey: "cat_name" },
        { title: "Is a Cat", classificationKey: "notCat" },
        { title: "False Event", classificationKey: "falseTrigger" },
        { title: "Start Interaction", classificationKey: "startinteraction" },
        { title: "End Interaction", classificationKey: "endinteraction" },
        { title: "Labels", classificationKey: "tags" },
        { title: "Firmware Version", classificationKey: "firmware_version" },
        { title: "Consumption", classificationKey: "consumption" },
        { title: "Extra Information", classificationKey: "description" },
        { title: "Video File Name", classificationKey: "file_name" },
        { title: "Session Video", classificationKey: "cloud_video_file_url" },
        { title: "YOLO Modal Images", classificationKey: "cloud_images" }
    ];

    React.useEffect(() => {
        dataLoad();
        video_download();
    }, [setlabelData]);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const openImage = () => {
        {
            imageURL.map((src, index) => (
                openImageViewer(index)
            ))
        }
    }

    const dataLoad = () => {
        const url = `/get-cat-feeder-label?event_id=${eventId}&session_start_date=${startTime}`;
        axiosInstance
            .get(url)
            .then((response) => {
                const item = response.data.data;

                setlabelData(prevObject => ({ ...prevObject, firmware_version: firmware_version }));
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const video_download = (event) => {
        axiosInstance.get('/get-cat-feeder-video-download?event_id=' + eventId + '&video_url=' + cloud_video_file_url)
            .then((response, error) => {
                if (error) {
                    return;
                }
                setVideoURL(response.data.video_url)
                setImageURL(response.data.image_urls)

            }).catch((error) => {
                setIsLoading(false);
            });
    }

    const handleDownload = () => {
        window.location.href = videoURL;
    };

    const renderFn = (key) => {
        if (key == "cloud_video_file_url") {
            if (videoURL != "") {

                return (
                    <a onClick={handleDownload} download style={{ color: "#096dd9", cursor: "pointer" }}>
                        Download Video
                    </a>)
            }
            else {
                return <span>No Video available for Download</span>
            }
        }
        else if (key == "cloud_images") {
            if (imageURL.length > 0) {

                return (
                    <a onClick={openImage} style={{ color: "#096dd9", cursor: "pointer" }}>Click Here to View Images</a>

                )
            }
            else {
                return <span>No Images available</span>
            }
        }
        else if (key != "cloud_video_file_url" && labelData[key] == undefined || labelData[key] == null) {
            return ""
        }

        else {
            return `${labelData[key]}`;
        }
    };

    const [newEventModalIsOpen, setEventModalIsOpen] = React.useState(false);


    return (

        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" fontWeight="bold">
                    Classification and Labels
                </Typography>
                {/* <HeaderButton startIcon={<EditIcon />}>
                    Edit Labels
                </HeaderButton> */}
                <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={() => setEventModalIsOpen(true)}
                    sx={{
                        backgroundColor: "#000",
                        color: "#fff",
                        borderRadius: "50px",
                        padding: "5px 15px",
                        textTransform: "none",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "12px",
                        "&:hover": {
                            backgroundColor: "#333",
                        },
                        "& .MuiButton-startIcon": {
                            marginRight: "5px",
                        },
                    }}
                >
                    Edit Labels
                </Button>
            </Box>

            {!isLoading ? (
                <TableContainer component={Paper}>
                    <Table aria-label="labels and classifications table">
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>Title</TableHeaderCell>
                                <TableHeaderCell>Value</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {values.map((v, i) => (
                                <TableRow key={i}>
                                    <TableCell>{v.title}</TableCell>
                                    <TableCell>{renderFn(v.classificationKey)}</TableCell>
                                    {/* <TableCell>{videoURL ? (
                    <a onClick={handleDownload} download style={{ color: "#096dd9", cursor: "pointer" }}>
                      Download Video
                    </a>) : (
                    <span>No Video available for Download</span>
                  )}</TableCell> */}
                                </TableRow>
                            ))}
                            {isViewerOpen && (
                                <ImageViewer
                                    src={imageURL}
                                    currentIndex={currentImage}
                                    disableScroll={false}
                                    closeOnClickOutside={true}
                                    onClose={closeImageViewer}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>) : (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100vh",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {newEventModalIsOpen && (
                <CatFeederEventLabelModal
                    isModalOpen={newEventModalIsOpen}
                    closeModal={() => setEventModalIsOpen(false)}
                    eventId={eventId}
                    startTime={startTime}
                    videoStartTime=""
                    machineId=""
                    submitCallback={() => dataLoad()}
                />
            )}
        </Box>
    );
};

export default CatFeederLabelsAndClassifications;
